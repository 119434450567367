import React from "react";
import parchment from "../assets/parchment.svg";

function R2() {
  return (
    <div className="flex flex-col items-center justify-center text-white  ">
       
       <div className="HOWTOBUY my-5 p-7 lg:p-3 bar1 rounded-xl lg:w-10/12 border-2 border-black bg-black/40 flex flex-col lg:flex-row  justify-center align-middle items-start lg:items-center">     <div className="left flex items-center justify-center lg:w-1/3">
         {/* <img src={wallet} alt="" className='w-40 m-4' /> */}
         <h2 className="russo text-4xl">PHASE 2</h2>
     </div>

     <div className="right lg:w-2/3">
     <h2 className=" russo text-3xl">Presale and Platform Development (Catnap - Naptime 2024)

</h2>
<p className=" russo text-[20px] lg:text-xl">
The excitement continues as we launch our presale event and list on Raydium. Our platform development will take off, with constant enhancements based on feedback from our community. We will audit platform security and prepare for our grand public launch.

 </p>
     </div>
  </div>
  
 </div>
  );
}

export default R2;
