import React, { useState } from 'react';
import snoozecatlogo from "../assets/snoozecatlogo.png";
import hamburger from "../assets/hamburger.svg";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <div className='hidden lg:flex flex-row justify-between items-center w-full borders-2'>

        <img src={snoozecatlogo} alt="" className="flex w-32 justify-self-end self-end p-2" />

        <div className="header  w-[1100px] flex flex-row items-center justify-between my-5 mr-10">
          <a href="/#" className='russo text-white lg:text-2xl' onClick={closeMenu}>Home</a>
          <a href="/#about" className='russo text-white lg:text-2xl' onClick={closeMenu}>About</a>
          <a href="/#howtobuy" className='russo text-white lg:text-2xl' onClick={closeMenu}>How To Buy</a>
          <a href="/#roadmap" target="_blank" rel="noopener noreferrer" className='russo text-white lg:text-2xl' onClick={closeMenu}>Roadmap</a>
          <a href="/#tokenomics" className='russo text-white lg:text-2xl' onClick={closeMenu}>Tokenomics</a>
          <a href="https://snoozecat.gitbook.io/usdzzzzcat-coin-whitepaper/" target="_blank" rel="noopener noreferrer" className='russo text-white lg:text-2xl' onClick={closeMenu}>Whitepaper</a>
          <a href="/Presale" target="_blank" rel="noopener noreferrer" className='russo text-white lg:text-2xl' onClick={closeMenu}>Join Presale now</a>
        </div>
      </div>

      <div className="hamburger flex lg:hidden justify-between items-center bg-black/5">
        <img src={snoozecatlogo} className='w-20' alt="" />
        <img src={hamburger} className='w-20 p-2' alt="" onClick={toggleMenu} />
      </div>

      {menuOpen && (
        <div className="mobile-menu lg:hidden bg-white text-black text-lg">
          <a href="/#" className='block p-2' onClick={closeMenu}>Home</a>
          <a href="/#about" className='block p-2' onClick={closeMenu}>About</a>
          <a href="/#howtobuy" className='block p-2' onClick={closeMenu}>How To Buy</a>
          <a href="/#roadmap" target="_blank" rel="noopener noreferrer" className='block p-2' onClick={closeMenu}>Roadmap</a>
          <a href="/#tokenomics" className='block p-2' onClick={closeMenu}>Tokenomics</a>
          <a href="https://snoozecat.gitbook.io/usdzzzzcat-coin-whitepaper/" target="_blank" rel="noopener noreferrer" className='block p-2' onClick={closeMenu}>Whitepaper</a>
          <a href="/Presale" target="_blank" rel="noopener noreferrer" className='block p-2' onClick={closeMenu}>Join Presale now</a>
        </div>
      )}
    </>
  );
}

export default Header;
