import React from 'react'
import maincat2 from "../assets/maincat2.svg"
import normalback2 from "../assets/normalback2.svg"
import cattop2 from "../assets/cattop2.svg" 

function Cat2() {
  return (
    <div className='w-full flex items-center justify-center lg:mt-20 p-2'>
    <div className='group relative flex justify-center items-center align-middle w-fit '>
        <img src={normalback2} alt="" className='w-52 lg:w-auto' />
        <div className="catbox absolute">
        <div className="innerbox relative flex items-center justify-center h-[250.49px] w-[317.32px] lg:bg-[#80E2FF] overflow-hidden mb-10">
            {/* <img src={boxybox2} alt="" className='absolute' /> */}
            <img src={maincat2} alt="" className='absolute max-w-none w-20 lg:w-[400px] z-10 group-hover:rotate-45 transition-all mb-[-30px] lg:mb-auto' />
        
            </div>
           
        </div>
        <img src={cattop2} alt="" className=' z-20 group-hover:rotate-45 w-8 lg:w-auto absolute top-[-20px] lg:top-[-80px] left-0 transition-all' />
    </div>
</div>
  )
}

export default Cat2