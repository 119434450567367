import React from 'react'
import mexc from '../assets/mexc.png'
import cmc from '../assets/cmc.png'
import coingecko from '../assets/coingecko.png'
import solscanlogo from '../assets/solscanlogo.png'
import dextools from '../assets/dextools.svg'
import raydium from '../assets/raydium.svg'

function Partners() {
  return (
    <div className='flex flex-col justify-center items-center  px-4 mb-10'>
      <h2 className="russo text-3xl lg:text-6xl text-center text-white [#C9B0E7] mb-6 lg:mb-5 uppercase">
      Partners
      </h2>
       
      <div className="visiontabs flex flex-row justify-between items-center mb-5 flex-wrap">
        <a href="https://solscan.io/token/Fh63NyhCi1iPZPF3BqZYk76b1yhbhZxw9qGCrrnBEet5" target="_blank" rel="noopener noreferrer">
        <img src={solscanlogo} alt="" className='mx-4 w-20 lg:w-auto m-2' />
        </a>
        <a href="https://www.coingecko.com/" target="_blank" rel="noopener noreferrer">
        <img src={coingecko} alt="" className='mx-4 w-20 lg:w-auto m-2' />
        </a>
        <a href="https://coinmarketcap.com/" target="_blank" rel="noopener noreferrer">
        <img src={cmc} alt="" className='mx-4 w-20 lg:w-auto m-2' />
        </a>
        <a href="https://www.mexc.com/" target="_blank" rel="noopener noreferrer">
        <img src={mexc} alt="" className='mx-4 w-20 lg:w-auto m-2' />
        </a>
        <a href="https://www.dextools.io/" target="_blank" rel="noopener noreferrer">
        <img src={dextools} alt="" className='mx-4 w-20 lg:w-auto m-2' />
        </a>
        <a href="https://raydium.io/" target="_blank" rel="noopener noreferrer">
        <img src={raydium} alt="" className='mx-4 w-20 lg:w-auto m-2' />
        </a>
    
      </div>
    </div>
  )
}

export default Partners