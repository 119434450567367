import React from 'react'
import pom from '../assets/pom.png'


function Pom() {
  return (
    <div className='my-14 flex flex-col justify-center items-center'>
 <h2 className="russo text-3xl lg:text-6xl text-center text-white [#C9B0E7] mb-4 lg:mb-5 uppercase">
        OUR PROOF-OF-MEME MECHANISM
      </h2>
        <img src={pom} alt="" />
        <p className="russo text-sm lg:text-xl text-white [#C9B0E7] w-11/12 lg:w-9/12 text-center">
        SnoozeCat is building a blockchain protocol that utilizes a unique consensus mechanism called Proof-of-Meme, enabling community members to submit and vote on their favorite SnoozeCat memes to validate transactions. Join our community today for the latest updates and be part of the future of decentralized finance!
      </p>
       
    </div>
  )
}


export default Pom