import React from "react"; 

function R1() {
  return (
    <div className="flex flex-col items-center justify-center text-white  ">
       
 <div className="HOWTOBUY my-5 p-7 lg:p-3 bar1 rounded-xl lg:w-10/12 border-2 border-black bg-black/40 flex flex-col lg:flex-row  justify-center align-middle items-start lg:items-center">        <div className="left flex items-center justify-center lg:w-1/3">
            {/* <img src={wallet} alt="" className='w-40 m-4' /> */}
            <h2 className="russo text-4xl">PHASE 1</h2>
        </div>

        <div className="right lg:w-2/3">
        <h2 className=" russo text-3xl">Project Initiation (Naptime - Catnap 2024)

</h2>
<p className=" russo text-[20px] lg:text-xl">
Our journey begins with the establishment of our project goals, website, and team. We will conduct thorough market research and build strong partnerships to ensure the success of our venture. Our tokenomics will be carefully finalized to prepare for our much-awaited presale.
    </p>
        </div>
     </div>
     
    </div>
  );
}

export default R1;
