import React, { useState, useEffect } from 'react';

function Presaletmier() {
  const calculateTimeLeft = () => {
    const difference = +new Date("2024-06-01") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className='flex flex-row'>
      <div className="box m-2 border-2 border-[#B797E4] rounded-xl lg:w-24 lg:h-24 w-[72px] h-[76px] flex flex-col justify-center items-center">
        <p className='text-3xl text-[#B797E4] font-medium '>{timeLeft.days}</p>
        <p className='text-md lg:text-xl text-[#B797E4] font-medium '>DAYS</p>
      </div>
      <div className="box m-2 border-2 border-[#B797E4] rounded-xl lg:w-24 lg:h-24 w-[72px] h-[76px] flex flex-col justify-center items-center">
        <p className='text-3xl text-[#B797E4] font-medium '>{timeLeft.hours}</p>
        <p className='text-md lg:text-xl text-[#B797E4] font-medium '>HOURS</p>
      </div>
      <div className="box m-2 border-2 border-[#B797E4] rounded-xl lg:w-24 lg:h-24 w-[72px] h-[76px] flex flex-col justify-center items-center">
        <p className='text-3xl text-[#B797E4] font-medium '>{timeLeft.minutes}</p>
        <p className='text-md lg:text-xl text-[#B797E4] font-medium '>MINS</p>
      </div>
      <div className="box m-2 border-2 border-[#B797E4] rounded-xl lg:w-24 lg:h-24 w-[72px] h-[76px] flex flex-col justify-center items-center">
        <p className='text-3xl text-[#B797E4] font-medium '>{timeLeft.seconds}</p>
        <p className='text-md lg:text-xl text-[#B797E4] font-medium '>SEC</p>
      </div>
    </div>
  );
}

export default Presaletmier;
