import axios from 'axios';
import React, { useState, useEffect } from 'react';

function PresaleLevel() {
  const [solRaised, setSolRaised] = useState(0);
  const [salePercentage, setSalePercentage] = useState(0);

  useEffect(() => {
    function fetchSolRaised() {
      axios.get('https://api.snoozecat.meme/api/balance')
        .then((res) => {
          setSolRaised(res.data);
          const percentage = (res.data * 100) / 4060;
          setSalePercentage(percentage);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    const interval = setInterval(fetchSolRaised, 1000);
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <div className='timebox w-full flex flex-col justify-center items-center'>
      <p className='text-3xl text-[#B797E4] font-medium mt-3 text-left self-start'>
        {solRaised} SOL
      </p>
      <div className="mb-7 box border-4 h-10 border-[#B797E4] w-full rounded-xl flex justify-start items-center align-baseline relative overflow-hidden">
        <div className="box border-4 h-20 border-[#B797E4] bg-[#B797E4] rounded-xl" style={{ width: `${salePercentage}%` }}>
        </div>
      </div>
    </div>
  );
}

export default PresaleLevel;
