import React from "react";
import twitter from "../assets/twitter.svg";
import telegram from "../assets/telegram.svg";
import discord from "../assets/discord.svg";
import medium from "../assets/medium.svg";
import tiktok from "../assets/tiktok.svg";

function Footer() {
  return (
    <div className="flex flex-col justify-center items-center  px-4 lg:px-16 mb-20">
      <div className="roadmaptabs flex  flex-row justify-between items-center mb-0">
        
        <a href="https://t.me/Snoozecatfelidae/1" target="_blank" rel="noopener noreferrer">
        <img src={telegram} alt="" className="ml-4 my-5 w-10 lg:w-10" />
        </a>
        <a href="https://twitter.com/snoozecat808?s=21&t=YNnUNNTH-XYID_0wNtuEtg" target="_blank" rel="noopener noreferrer">
        <img src={twitter} alt="" className="ml-4 my-5 w-10 lg:w-10" />
        </a>
        <a href="https://www.tiktok.com/@snoozecat.zzzzcat?_t=8mYmqzaBYkH&_r=1" target="_blank" rel="noopener noreferrer">
        <img src={tiktok} alt="" className="ml-4 my-5 w-10 lg:w-10" />
        </a>
        <a href="https://medium.com/@snoozecat808" target="_blank" rel="noopener noreferrer">
        <img src={medium} alt="" className="ml-4 my-5 w-10 lg:w-10" />
        </a>
       
      </div>

        <p className="russo text-xl lg:text-2xl text-white [#C9B0E7]  font-thin lg:w-6/12 text-center  ">
          @snoozecat 2024
        </p>
    </div>
  );
}

export default Footer;
