import React from 'react'
import Hero from '../components/Hero'
import Contract from '../components/Contract'
import Welcome from '../components/Welcome'
import About from '../components/About'
import Vision from '../components/Vision'
import Comingsoon from '../components/Comingsoon'
import Partners from '../components/Partners'
import Tokenomics from '../components/Tokenomics'
import Roadmap from '../components/Roadmap' 
import Howtobuy from '../components/Howtobuy'
import Pom from '../components/Pom'

function Home() {
    return (
        <div>
            <Hero />
            <Contract />
            <Welcome />
            <About />
            <Vision />
            {/* <Comingsoon /> */}
            <Pom/>
            <Tokenomics />
            <Roadmap />
            <Howtobuy />
            <Partners />
        </div>
    )
}

export default Home