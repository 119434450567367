import React from 'react'
import vision1 from '../assets/vision1.svg'
import vision2 from '../assets/vision2.svg'
import vision3 from '../assets/vision3.svg'
import Groupcat from '../components/Groupcat'  

function Vision() {
  return (
    <div className='flex flex-col justify-center items-center  px-4 mb-10'>
      
       
      <h2 className="russo text-3xl lg:text-6xl text-center text-white [#C9B0E7] mb-4 lg:mb-5 uppercase">
      Vision
      </h2>
      <Groupcat/> 
      {/* <div className="visiontabs flex flex-col lg:flex-row justify-between mb-5">
        <img src={vision1} alt="" />
        <img src={vision2} alt="" />
        <img src={vision3} alt="" />
      </div> */}
 
      <p className="russo text-sm lg:text-xl text-white [#C9B0E7] w-11/12 lg:w-9/12 text-center">
     
SnoozeCat envisions a world where laughter knows no bounds and digital assets are as fun as they are
functional. Our goal is to create a meme coin that not only brings joy to its holders but also serves as a
catalyst for positive change in the crypto community.

      </p>
    </div>
  )
}

export default Vision