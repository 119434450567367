import React from 'react'

function Presale2() {
  return (
    <div className="flex items-center justify-center mb-10 p-4">
             
    <div className='p-4 bg-[#B797E4] lg:w-10/12 rounded-xl flex flex-col justify-center items-center'>
        <h2 className="russo text-5xl text-center h-fullz lg:text-7xl text-black uppercase my-4  ">
        PRESALE launch🚀: TBA

        </h2>

        {/* <p className='text-left text-2xl text-black font-medium mb-4 mt-3 lg:text-center'>Thank you for your interest. Our platform is currently under development, and the smart contract audit is in progress. 
        <br/>
        We are committed to transparency and security, and the audit report will be available here before the platform launch.
        <br/>
        We appreciate your patience and support.
        <br/>
Best regards,
Snoozecat( Feliscatus)</p>
      */}


    </div>
</div>
  )
}

export default Presale2