import Home from './pages/Home'
import Presale2 from './pages/Presale2'
import Presale from './pages/Presale'
import Footer from './components/Footer'
import Header from './components/Header'
import blursnooze from "./assets/blursnooze.svg"
 
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"; 
import Chart from './pages/Chart'
import Comingsoon from './pages/Comingsoon'
import Error404 from './pages/Error404'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "/Presale",
    element: <Presale2/>,
  },
  {
    path: "/Presale2",
    element: <Presale/>,
  },
  {
    path: "/Chart",
    element: <Chart/>,
  },
  {
    path: "/Comingsoon",
    element: <Comingsoon/>,
  },
  {
    path: "*",
    element: <Error404/>,
  },
]);


function App() {
  return (
 
      <div className="App relative overflow-hidden russo">
        <img src={blursnooze} alt="" className='absolute max-w-none w-[2000px] -top-[800px] left-[300px] -z-10' />
        <Header />
      
        <RouterProvider router={router} />
      
        <Footer />
      </div>
    
  );
}

export default App;
