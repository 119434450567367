import React from 'react'
import { Fade, Slide } from "react-awesome-reveal";
import tokenomicsimg from '../assets/tokenomicsimg.svg'

function Tokenomics() {
  return (
    <div id='tokenomics' className='flex flex-col justify-center items-center  px-4 lg:px-16 mb-20'>

      <div className="flex flex-col lg:flex-row justify-center items-center">
        
        <div className='lg:w-6/12 mb-5'>
          <Slide direction='left'>
          <img src={tokenomicsimg} alt="" className='' />
          </Slide>
        </div>      
        
        <p className="russo text-xl lg:text-2xl text-white [#C9B0E7]   lg:w-6/12 text-center lg:text-left ">
          Distribution: Initial token sale(presale) 50%, liquidity pool seeding 30%, airdrops 10%,
          and community rewards 10%
          <br /><br />
          Utility: Voting rights, access to exclusive SnoozeCat merchandise, and participation in meme
          contests
        </p>
      </div>
    </div>
  )
}

export default Tokenomics