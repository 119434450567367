import React from 'react'
import { Fade, Slide } from "react-awesome-reveal";
import tokenomicsimg from '../assets/tokenomicsimg.svg'
import roadmap from '../assets/roadmap.webp'
import R1 from '../sections/R1'
import R2 from '../sections/R2'
import R3 from '../sections/R3'


function Roadmap() {
   return (
      <div id='roadmap' className='flex flex-col justify-center items-center  px-4 lg:px-16 mb-20'>
         <h2 className="russo text-3xl lg:text-6xl lgs:self-end text-center   text-white [#C9B0E7] mb-3 lg:mb-5 uppercase">
            Roadmap
         </h2>

         <div className="flex flex-col lg:flex-row-reverse justify-center items-center">
            <div className="right flex flex-col justify-center items-center lg:w-1/2">

               <p className="russo text-sm lg:text-xl text-white [#C9B0E7] w-11/12 lg:w-9/12 text-center lg:text-left">
                  Introducing the SnoozeCat Project Roadmap - the next big thing in the world of cryptocurrency. Our roadmap is designed to take you on a journey through our three-phase plan, each step bringing us closer to achieving our goal of becoming a leader in the Solana blockchain.
               </p>

            </div>

            <div className="left lg:w-1/2 p-4   ">
               <img src={roadmap} alt="" className='rounded-xl w-96 lg:w-[500px]' />
            </div>
         </div>

            <R1 />
            <R2 />
            <R3 />
       


         <p className="russo text-sm lg:text-xl text-white [#C9B0E7] w-11/12 lg:w-9/12 text-center">


            Our roadmap is structured to maintain clarity and structure while adding a playful touch through its cat-themed time intervals. Join us on this exciting journey towards success and watch as we become one of the most prominent names in the Solana blockchain.


         </p>
      </div>
   )
}

export default Roadmap