import React, { useRef, useState } from 'react';
import iconclose from '../assets/iconclose.svg'
import qr from '../assets/qr.png'

function PresalePopup({ onClose }) {
    const [isCopied, setIsCopied] = useState(false);
    const addressRef = useRef(null);

    const copyToClipboard = () => {
        // Select the text field
        addressRef.current.select();
        // Copy the text inside the text field
        document.execCommand('copy');
        // Deselect the text field
        window.getSelection().removeAllRanges();
        // Show "Copied" text
        setIsCopied(true);
        // Hide "Copied" text after 2 seconds
        setTimeout(() => {
            setIsCopied(false);
        }, 2000);
    };

    return (
        <>
            <div className="fixed top-1/2 bottom-1/2 w-full flex items-center justify-center z-50 ">
            <div className="relative p-4 border-4 border-[#cfb3f6] bg-[#B797E4] w-3/4 lg:w-1/2 rounded-xl flex flex-col justify-center items-center"> 
                <p className='text-3xl text-[#000000] font-medium mb-4'>
                    JOIN $Zzzzcat PRESALE
                </p>

                <img src={iconclose} alt="" onClick={onClose} className='absolute top-5 right-5' />
 
                    <p className='text-lg text-[#000000] font-medium mb-4'>
                        Send SOL to the address below to participate in the presale, you can also scan the QR Code.
                    </p>
                    
                    <img src={qr} alt="" className='my-5  ' />

                    <div className="textbox flex items-center justify-center">
                        <input
                            ref={addressRef}
                            type="text"
                            className="p-2 rounded-s-lg"
                            value="AebbaXZYsziWGq2W8YcdUDYa9PwevKLv8rhWEvNkYSJp"
                            readOnly
                        />
                        <button
                            onClick={copyToClipboard}
                            className="rounded-e-lg bg-black text-white p-2 text-2xl"
                        >
                            <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 448 512"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M208 0H332.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V336c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V48c0-26.5 21.5-48 48-48zM48 128h80v64H64V448H256V416h64v48c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48z"></path>
                            </svg>
                        </button>
                    </div>
                    {isCopied && <p className="text-black">Copied</p>}
                </div>
            </div>
        </>
    );
}

export default PresalePopup;
