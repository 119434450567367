import React from 'react'
import cat from '../assets/cat.png'
import mewtop from '../assets/mewtop.png'
import planetbig from "../assets/planetbig.svg"
import Meow from './animations/Meow'


function Welcome() {
  return (
    <div className='flex flex-col lg:flex-row items-center justify-center mx-5 lg:mx-20 relative'>
      <img src={planetbig} alt="" className="absolute top-0 right-60  lg:left-[-550px] -z-10" />
    <div className="left z-10">

   {/* <Meow/> */}
   <Meow/>
        <p className="z-10 russo text-xl lg:text-2xl text-white [#C9B0E7]   lg:w-7/12 text-center lg:text-left ">
        Hey there, fellow catnappers! Feeling sleepy and bored with counting sheep? Well, have we got the solution for you! Join the hilarious snooze cat meme party with $ZZZZCAT - the ultimate way to invest in your catnap dreams and get your daily dose of laughter and profits. 
        <br/><br/>
      </p>
    </div>

      <img src={cat} alt=""  className='w-[500px] lg:mb-40 animate-bounce'/>
    </div>
  )
}

export default Welcome