import React from 'react'
import parchment from '../assets/howtobuyback.svg'
import solanalogo from '../assets/solanalogo.svg'
import wallet from '../assets/wallet.png'
import snoozecatlogo from "../assets/snoozecatlogo.png";
import transfer from "../assets/send.svg";
import send from "../assets/send.png";

function Howtobuy() {
  return (
    <div id='howtobuy' className='text-white     flex flex-col justify-center items-center  px-4 mb-10'>
      
       
      <h2 className="russo text-3xl lg:text-6xl text-center text-white [#C9B0E7] mb-4 lg:mb-5 uppercase">
      HOW TO BUY
      </h2>

      
       

      {/* <div className="roadmaptabs flex flex-col lg:flex-row justify-between items-center mb-5">
        
        <div className="ROADMAP relative flex flex-row justify-center items-center align-middle">
          <img src={wallet} alt="" className="mr-4s my-5 w-[500px]d lg:w-[600px]" />
          <div className="absolute lg:p-14 p-5">
            <h2 className=" russo text-[#452A9B]">1. Set Up a Secure Solana Wallet:
</h2>
            <p className="text-[#452A9B] russo text-[20px]">
            To begin, establish a Solana-compatible wallet to safely manage your digital assets. We recommend using Phantom or Trust Wallet for their robust security features and user-friendly interfaces. These wallets allow you to interact directly with decentralized applications (dApps) on the Solana blockchain, providing a seamless experience throughout the presale process.


            </p>
          </div>
        </div>

        <div className="ROADMAP relative flex flex-row justify-center items-center align-middle">
          <img src={parchment} alt="" className="mr-4s my-5 w-[500px] lg:w-[600px]" />
          <div className="absolute lg:p-14 p-5">
            <h2 className=" russo text-[#452A9B]">2. Acquire $SOL from a Reputable Exchange:

</h2>
            <p className="text-[#452A9B] russo text-[20px]">
            Purchase Solana ($SOL) tokens through leading cryptocurrency exchanges such as Binance or Coinbase. These platforms are known for their high liquidity, allowing you to buy and sell $SOL quickly and at competitive market rates. Ensure to complete any necessary KYC (Know Your Customer) procedures to enable full trading capabilities.


              <br />
            </p>
          </div>
        </div>

        <div className="ROADMAP relative flex flex-row justify-center items-center align-middle">
          <img src={parchment} alt="" className="mr-4s my-5 w-[500px] lg:w-[600px]" />
          <div className="absolute lg:p-14 p-5">
            <h2 className=" russo text-[#452A9B]">Transfer $SOL to Your Personal Wallet:

</h2>
            <p className="text-[#452A9B] russo text-[20px]">
            Once you have acquired $SOL, transfer the tokens to your personal Solana wallet. This move is crucial as it shifts your tokens to a self-custodial wallet, where you have complete control over your assets. This step enhances security and is essential for participating in the presale, as tokens need to be sent from a wallet that supports smart contract interactions.


            </p>
          </div>
        </div>
      </div> */}
     
     <div className="HOWTOBUY my-5 p-7 lg:p-3 bar1 rounded-xl lg:w-10/12 border-2 border-black bg-black/40 flex flex-col lg:flex-row  justify-center align-middle items-center">
        <div className="left flex items-center justify-center lg:w-1/3">
            <img src={wallet} alt="" className='w-40 m-4' />
        </div>

        <div className="right lg:w-2/3">
        <h2 className=" russo text-3xl">1. Set Up a Secure Solana Wallet:
</h2>
<p className=" russo text-[20px] lg:text-xl">
            To begin, establish a Solana-compatible wallet to safely manage your digital assets. We recommend using Phantom or Trust Wallet for their robust security features and user-friendly interfaces. These wallets allow you to interact directly with decentralized applications (dApps) on the Solana blockchain, providing a seamless experience throughout the presale process.


            </p>
        </div>
     </div>
     



     <div className="HOWTOBUY my-5 p-7 lg:p-3 bar1 rounded-xl lg:w-10/12 border-2 border-black bg-black/40 flex flex-col lg:flex-row  justify-center align-middle items-center">
        <div className="left flex items-center justify-center lg:w-1/3">
            <img src={solanalogo} alt="" className='w-40 m-4' />
        </div>

        <div className="right lg:w-2/3">
        <h2 className=" russo text-3xl">2. Acquire $SOL from a Reputable Exchange:

</h2>
<p className=" russo text-[20px] lg:text-xl">
Purchase Solana ($SOL) tokens through leading cryptocurrency exchanges such as Binance or Coinbase. These platforms are known for their high liquidity, allowing you to buy and sell $SOL quickly and at competitive market rates. Ensure to complete any necessary KYC (Know Your Customer) procedures to enable full trading capabilities.



            </p>
        </div>
     </div>
        


        
     <div className="HOWTOBUY my-5 p-7 lg:p-3 bar1 rounded-xl lg:w-10/12 border-2 border-black bg-black/40 flex flex-col lg:flex-row  justify-center align-middle items-center">
        <div className="left flex items-center justify-center lg:w-1/3">
            <img src={transfer} alt="" className='w-40 m-4' />
        </div>

        <div className="right lg:w-2/3">
        <h2 className=" russo text-3xl">3. Transfer $SOL to Your Personal Wallet:


</h2>
<p className=" russo text-[20px] lg:text-xl">
Once you have acquired $SOL, transfer the tokens to your personal Solana wallet. This move is crucial as it shifts your tokens to a self-custodial wallet, where you have complete control over your assets. This step enhances security and is essential for participating in the presale, as tokens need to be sent from a wallet that supports smart contract interactions.



            </p>
        </div>
     </div>
        


        
        <div className="HOWTOBUY my-5 p-7 lg:p-3 bar1 rounded-xl lg:w-10/12 border-2 border-black bg-black/40 flex flex-col lg:flex-row  justify-center align-middle items-center">
           <div className="left flex items-center justify-center lg:w-1/3">
               <img src={send} alt="" className='w-40 m-4' />
           </div>
   
           <div className="right lg:w-2/3">
           <h2 className=" russo text-3xl">4. Send $SOL to the Snoozecat Presale Address:

   
   
   </h2>
   <p className=" russo text-[20px] lg:text-xl">
   Navigate to the official Snoozecat presale website or platform, and carefully enter the designated presale address to send your $SOL. It's vital to double-check the address to avoid phishing scams or errors. Transferring from your personal wallet (not from an exchange) ensures that the transaction can be linked directly to you for token distribution.

   
   
   
               </p>
           </div>
        </div>
        


        
        <div className="HOWTOBUY my-5 p-7 lg:p-3 bar1 rounded-xl lg:w-10/12 border-2 border-black bg-black/40 flex flex-col lg:flex-row  justify-center align-middle items-center">
           <div className="left flex items-center justify-center lg:w-1/3">
               <img src={snoozecatlogo} alt="" className='w-40 m-4' />
           </div>
   
           <div className="right lg:w-2/3">
           <h2 className=" russo text-3xl">5. Receive Your Tokens Post-Presale:


   
   
   </h2>
   <p className=" russo text-[20px] lg:text-xl">
   After the presale concludes, Snoozecat tokens ($Zzzzcat) will be airdropped directly to the wallet address you used to participate. This automated process simplifies token distribution and ensures that you receive your tokens promptly and securely.


   
   
   
               </p>
           </div>
        </div>


        <h2 className="mt-10 russo text-3xl lg:text-6xl text-center lg:text-right text-white [#C9B0E7] mb-4 lg:mb-5 uppercase">
        Why Invest in Snoozecat ($Zzzzcat)?

      </h2>

      <p className="russo text-center text-sm lg:text-xl text-white [#C9B0E7] w-11/12 lg:w-10/12 text-left mb-20 lg:mb-auto">
      The Snoozecat project leverages the speed and low transaction costs of the Solana blockchain, promising a scalable and efficient ecosystem for its users. By participating in the presale, you gain early access to Snoozecat tokens at an advantageous price, positioning you favorably as the project grows and enters broader markets.
      <br/><br/>
Ensure your investment by following the above steps carefully, and join us in pioneering the next big innovation on Solana!
    
           </p>
    </div>
  )
}

export default Howtobuy