import React from 'react'
import { Fade } from "react-awesome-reveal";
import lpbox from '../assets/lpbox.svg'
import contractbox from '../assets/contractbox.svg'
import audit from '../assets/audit.svg'
import footsteps from '../assets/footsteps.svg'

function Contract() {
  return (
    <div className='flex flex-col justify-center items-center px-4 lg:px-0 min mt-10 mb-10 relative'>
      <img src={footsteps} alt="" className='absolute -left-72 -z-10 animate-pulse' />
      <Fade>

      <h2 className="russo text-3xl text-center text-white [#C9B0E7] mb-2 lg:mb-5 uppercase">
      Contract Address
      </h2>
      <img src={contractbox} alt="" className='z-10 my-4' />
      <img src={lpbox} alt="" className='z-10 my-4' />
      <a href="/Comingsoon" target="_blank" rel="noopener noreferrer">
      <img src={audit} alt="" className='z-10 w-80 lg:w-auto mt-10 lg:mx-10'/>
      </a>
        
      </Fade>
    </div>
  )
}

export default Contract