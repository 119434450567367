import React from 'react'
import Presalehero from '../components/Presalehero'
import Salepart from '../components/Salepart'
import Howtobuypresale from '../components/Howtobuypresale'
import Roadmap from '../components/Roadmap'
import Tokenomics from '../components/Tokenomics'

function Presale() {
  return (
    <div className="flex flex-col p-4 lg:p-10 relative">
      <Presalehero/>
      <Salepart/>
      <Howtobuypresale/>
      <Roadmap/>
      <Tokenomics/>
    </div>
  )
}

export default Presale