import React, { useState } from 'react';
import snoozecatlogo from "../assets/snoozecatlogo.png";
import buyzzzcolor from "../assets/buyzzzcolor.svg";
import howtobuycolor from "../assets/howtobuycolor.svg";
import Presaletmier from './Presaletmier';
import PresalePopup from './PresalePopup';
import iconclose from '../assets/iconclose.svg'
import PresaleLevel from './PresaleLevel';

function Salepart() {
    const [showPopup, setShowPopup] = useState(false);

    const handlePopupToggle = () => {
        setShowPopup(!showPopup);
    };

    return (
        <div className='flex flex-col lg:flex-row items-center justify-center align-middle  mb-10 '>
        {showPopup && <PresalePopup onClose={handlePopupToggle} />}
            <div className='lg:w-1/3 m-5 flex justify-center items-center'>
                <img src={snoozecatlogo} alt="" className="w-96 max-w-none lg:w-[700px] z-10" />
            </div>
            <div className="text lg:w-1/3 mb-10 lg:mb-0">
                <p className='text-xl text-[#B797E4] font-medium mb-4'>
                    Presale duration : 25days 
                </p>
                <p className='text-3xl text-[#B797E4] font-medium mb-4'>
                Minimum buy : 0.2 sol 
                <br/>
Maximum buy : 100 sol 

                </p>
                <p className='text-xl text-[#B797E4] font-medium mb-4'>
                Presale price : 0.00012 USD per $Zzzzcat
                <br/>
1$Zzzzcat = 0.00000812 sol 

                </p>
                <p className='text-xl text-[#B797E4] font-medium mb-4'>
                Listing price on Dex (Raydium): 0.0013 USD per $Zzzzcat
                <br/>
Listing on Dex : TBA
<br/>
CEX listing (MEXC):TBA

                </p>

                <div className="btns flex flex-row items-center justify-between lg:justify-start">
                    <img src={buyzzzcolor} alt="" className='pr-2 w-48' onClick={handlePopupToggle} />
                    <a href="/#howtobuy" target="_blank" rel="noopener noreferrer">
                    <img src={howtobuycolor} alt="" className='pr-2 w-48' />
                    </a>
                </div>
            </div>

               <div className="timebox w-full lg:w-1/3 border-4 border-[#B797E4] rounded-2xl flex flex-col justify-center items-center p-4">

                <p className='text-3xl text-[#B797E4] font-medium mb-4 mt-3'>
                    PRESALE ENDS IN
                </p>

                <Presaletmier />
 
<PresaleLevel/>
               

                <img src={buyzzzcolor} alt="" className='pr-2 w-48' onClick={handlePopupToggle} />
                <p className='text-xl text-[#B797E4] font-extralight mb-4 mt-3 text-center'>
                    Do not send sol from an exchange like coinbase or binance.
                </p>
            </div>
             
        </div>
    );
}

export default Salepart;
