import React from 'react'
import maincat3 from "../assets/maincat3.svg"
import normalback3 from "../assets/normalback3.svg"
import cattop3 from "../assets/cattop3.svg" 

function Cat3() {
  return (
    <div className='w-full flex items-center justify-center lg:mt-20 p-2'>
    <div className='group relative flex justify-center items-center align-middle w-fit '>
        <img src={normalback3} alt="" className='w-52 lg:w-auto' />
        <div className="catbox absolute">
        <div className="innerbox relative flex items-center justify-center h-[250.49px] w-[317.32px] lg:bg-[#80E2FF] overflow-hidden mb-10">
            {/* <img src={boxybox2} alt="" className='absolute' /> */}
            <img src={maincat3} alt="" className='absolute max-w-none w-12 lg:w-[200px] z-10 group-hover:-rotate-45 transition-all mb-[-30px] lg:mb-auto' />
        
            </div>
           
        </div>
        <img src={cattop3} alt="" className=' z-20 group-hover:rotate-45 w-8 lg:w-auto absolute top-[-20px] lg:top-[-80px] left-0 transition-all' />
    </div>
</div>
  )
}

export default Cat3