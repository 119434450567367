import React from 'react'

function Error404() {
  return (
    <div className="flex items-center justify-center mb-10 p-4">
             
    <div className='p-4 bg-[#B797E4] lg:w-10/12 rounded-xl flex flex-col justify-center items-center'>
        <p className='text-left text-lg text-black font-medium mb-4 mt-3'>404 - Page Not Found</p>
        {/* <h2 className="russo text-5xl text-center h-fullz lg:text-7xl text-black uppercase my-4  ">
       PURR, NOTHING TO SEE HERE
        </h2> */}

        {/* <p className='text-left text-2xl text-black font-medium mb-4 mt-3'>Curiosity kills the cat, you know!</p> */}
        {/* <div className="div flex flex-col lg:flex-row lg:w-11/12 mb-5"> 

            <div className="1 flex flex-col justify-start items-start lg:w-1/2">
                <p className='text-left text-2xl text-black font-medium mb-4 mt-3'>
                STEP 1: SET UP A SECURE SOLANA WALLET
                </p>
                <p className='text-left text-2xl text-black font-medium mb-4 mt-3'>
                STEP 2: ACQUIRE SOL FROM A REPUTABLE EXCHANGE
                </p>
                <p className='text-left text-2xl text-black font-medium mb-4 mt-3'>
                STEP 3: TRANSFER SOL TO YOUR PERSONAL WALLET
                </p>
                <p className='text-left text-2xl text-black font-medium mb-4 mt-3'>
                STEP 3: SEND $SOL TO THE SNOOZECAT PRESALE ADDRESS
                </p>
                <p className='text-left text-2xl text-black font-medium mb-4 mt-3'>
                STEP 3: RECEIVE YOUR TOKENS POST-PRESALE
                </p>
                  </div>

        </div> */}


    </div>
</div>
  )
}

export default Error404