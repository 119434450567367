import React from 'react'
import maincat1 from "../assets/maincat1.svg"
import normalback1 from "../assets/normalback1.svg"
import cattop1 from "../assets/cattop1.svg"
import boxybox1 from "../assets/boxybox1.svg"

function Cat1() {
    return (
        <div className='w-full flex items-center justify-center lg:mt-20 p-2'>
            <div className='group relative flex justify-center items-center align-middle w-fit '>
                <img src={normalback1} alt="" className='w-52 lg:w-auto' />
                <div className="catbox absolute">
                    <div className="innerbox relative flex items-center justify-center h-[250.49px] w-[317.32px] lg:bg-[#80E2FF] overflow-hidden mb-10">
                    {/* <img src={boxybox1} alt="" className='absolute' /> */}
                    <img src={maincat1} alt="" className='absolute max-w-none w-24 lg:w-[600px] z-10 group-hover:-rotate-45 transition-all  mb-[-30px] lg:mb-auto' />
                
                    </div>
                   
                </div>
                <img src={cattop1} alt="" className=' z-20 group-hover:rotate-45 w-8 lg:w-auto absolute top-[-20px] lg:top-[-80px] left-0 transition-all' />
            </div>
        </div>
    )
}

export default Cat1