import React from "react"; 
import { Fade } from "react-awesome-reveal";
import snoozecatlogo from "../assets/snoozecatlogo.png";
import herotext from "../assets/herotext.svg";
import buynow from "../assets/buynow.svg";
import charts from "../assets/charts.svg";
import planetsmall from "../assets/planetsmall.svg";
import planetbig from "../assets/planetbig.svg";
import background from "../assets/background.png";
import planetgif from "../assets/planetgif.gif";

function Hero() {
  return (
    <div className="lg:px-10 px-4 flex flex-col lg:flex-row-reverse justify-center items-center relative mb-20 lg:mb-20 overflow-clipd">

      <Fade cascade>
<img src={planetbig} alt="" className="moving-object absolute top-1 left-60 lg:top-[-300px] lg:left-[900px] -z-10" />
 
      <img src={planetsmall} alt="" className="moving-objectinverse absolute top-[200px] left-[-150px] -z-10" />
      </Fade>
      <div className="right lg:w-1/3 flex justify-center items-center">

      <img src={snoozecatlogo} alt="" className="w-96 max-w-none lg:w-[700px] z-10" />
      </div>
      <div className="left lg:w-2/3">
      <h1 className="russo text-6xl text-center lg:text-left lg:text-8xl text-[#C9B0E7] uppercase mb-2">
        Explore SnoozeCat
      </h1>
      
      <h2 className="russo text-3xl lg:text-4xl text-center lg:text-left text-[#C9B0E7] mb-2 lg:mb-5 uppercase">
        The dreamiest meme on Solana! 😹🐾💤🚀
      </h2>
      <p className="russo text-sm lg:text-xl text-white [#C9B0E7] w-11/12 lg:w-9/12 text-center lg:text-left">
        SnoozeCat is just a cat that loves to snooze and dream of ways to get
        wealthy with $ZZZZCAT coin. This project is inspired by every cat's love
        for a good nap and the internet's love for all things meme. 
        We created
        SnoozeCat to bring some fun and humor into the world of crypto. 
        {/* Join our
        purr-fect plan where stacking coins meets catnaps! Be part of a
        community that believes in prosperity, relaxation, and a healthy dose of
        humor. Invest in SnoozeCat and let's make some money together while
        having fun! 😻💰 */}
      </p>
      {/* <img src={herotext} alt="" className='w-96 s lg:w-[600px]'/> */}

      <div className="btns flex flex-col lg:flex-row items-center lg:justify-start ">
        <a href="/chart" target="_blank" rel="noopener noreferrer">
        <img src={charts} alt="" className="w-80 lg:w-auto mt-10 lg:mr-10" />
        </a>
        <a href="/presale" target="_blank" rel="noopener noreferrer">
        <img src={buynow} alt="" className="w-80 lg:w-auto mt-10 lg:mr-10" />
        </a>
      </div>
      </div>
    
      <img src={background} alt="" className='absolute -z-10 mix-blend-screen max-w-none w-[1000px] lg:w-full'/>
    </div>
  );
}

export default Hero;
