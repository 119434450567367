import React from 'react'

function Comingsoon() {
  return (
    <div className="flex items-center justify-center mb-10 p-4">
             
    <div className='p-4 bg-[#B797E4] lg:w-10/12 rounded-xl flex flex-col justify-center items-center'>
        <h2 className="russo text-5xl text-center h-fullz lg:text-7xl text-black uppercase my-4  ">
        Smart Contract Audit: Coming Soon

        </h2>

        <p className='text-left text-2xl text-black font-medium mb-4 mt-3 lg:text-center'>Thank you for your interest. Our platform is currently under development, and the smart contract audit is in progress. 
        <br/>
        We are committed to transparency and security, and the audit report will be available here before the platform launch.
        <br/>
        We appreciate your patience and support.
        <br/>
Best regards,
Snoozecat( Feliscatus)</p>
        {/* <div className="div flex flex-col lg:flex-row lg:w-11/12 mb-5"> 

            <div className="1 flex flex-col justify-start items-start lg:w-1/2">
                <p className='text-left text-2xl text-black font-medium mb-4 mt-3'>
                STEP 1: SET UP A SECURE SOLANA WALLET
                </p>
                <p className='text-left text-2xl text-black font-medium mb-4 mt-3'>
                STEP 2: ACQUIRE SOL FROM A REPUTABLE EXCHANGE
                </p>
                <p className='text-left text-2xl text-black font-medium mb-4 mt-3'>
                STEP 3: TRANSFER SOL TO YOUR PERSONAL WALLET
                </p>
                <p className='text-left text-2xl text-black font-medium mb-4 mt-3'>
                STEP 3: SEND $SOL TO THE SNOOZECAT PRESALE ADDRESS
                </p>
                <p className='text-left text-2xl text-black font-medium mb-4 mt-3'>
                STEP 3: RECEIVE YOUR TOKENS POST-PRESALE
                </p>
                  </div>

        </div> */}


    </div>
</div>
  )
}

export default Comingsoon