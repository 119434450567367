import React from 'react'
import finale from '../assets/finale.png'
function Presalehero() {
  return (
<div className='flex flex-col items-center justify-center mb-10'>
       <h2 className="russo text-6xl text-center lg:text-left lg:text-7xl text-[#C9B0E7] uppercase mb-6 mt-4 rotate-6 ">
        $Zzzzcat
      </h2>
      
       <h2 className="russo text-6xl text-center lg:text-left lg:text-7xl text-[#C9B0E7] uppercase mb-3 -rotate-6 ">
        PRESALE
      </h2>

      <img src={finale} alt="" className='w-[1000px]' />

    </div>  )
}

export default Presalehero