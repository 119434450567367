import React from 'react'
import Cat1 from './Cat1'
import Cat2 from './Cat2'
import Cat3 from './Cat3'

function Groupcat() {
  return (
    <div className='flex  flex-row'>
        <Cat1/>
        <Cat2/>
        <Cat3/>
    </div>
  )
}

export default Groupcat