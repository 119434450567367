import React from 'react'
import meowgif from "../../assets/meowgif.gif"

function Meow() {
  return (
    <div className=' overflow-hidden flex items-center justify-start align-middle'>
      <h2>
        
    <img src={meowgif} alt=""  className='w-[500px] -mb-[150px]  lg:-mb-[200px] overflow-hidden'/>
        </h2>  
    </div>
  )
}

export default Meow