import React, { useState } from 'react';
import howtoimg from "../assets/howtoimg.png";
import buyzzzblack from "../assets/buyzzzblack.svg";
import PresalePopup from './PresalePopup';

function Howtobuypresale() {
    const [showPopup, setShowPopup] = useState(false);

    const handlePopupToggle = () => {
        setShowPopup(!showPopup);
    };

    return (
        <div className="flex items-center justify-center mb-10">
             {showPopup && <PresalePopup onClose={handlePopupToggle} />}
            <div className='p-4 bg-[#B797E4] lg:w-10/12 rounded-xl flex flex-col justify-center items-center'>
                <h2 className="russo text-5xl text-center lg:text-left lg:text-7xl text-black uppercase my-4  ">
                    HOW TO BUY $Zzzzcat
                </h2>

                <div className="div flex flex-col lg:flex-row lg:w-11/12 mb-5"> 

                    <div className="1 flex flex-col justify-start items-start lg:w-1/2">
                        <p className='text-left text-2xl text-black font-medium mb-4 mt-3'>GUIDE</p>
                        <p className='text-left text-2xl text-black font-medium mb-4 mt-3'>
                        STEP 1: SET UP A SECURE SOLANA WALLET
                        </p>
                        <p className='text-left text-2xl text-black font-medium mb-4 mt-3'>
                        STEP 2: ACQUIRE SOL FROM A REPUTABLE EXCHANGE
                        </p>
                        <p className='text-left text-2xl text-black font-medium mb-4 mt-3'>
                        STEP 3: TRANSFER SOL TO YOUR PERSONAL WALLET
                        </p>
                        <p className='text-left text-2xl text-black font-medium mb-4 mt-3'>
                        STEP 3: SEND $SOL TO THE SNOOZECAT PRESALE ADDRESS
                        </p>
                        <p className='text-left text-2xl text-black font-medium mb-4 mt-3'>
                        STEP 3: RECEIVE YOUR TOKENS POST-PRESALE
                        </p>
                        <img src={buyzzzblack} alt="" className='mb-5'  onClick={handlePopupToggle} />
                    </div>

                    <div className="2 lg:w-1/2">
                        <img src={howtoimg} alt="" />
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Howtobuypresale