import React from 'react'
import aboutpic from "../assets/aboutpic.webp";

function About() {
  return (
    <div id='about' className='flex flex-col-reverse lg:flex-row justify-center items-center  px-4 mb-10'>
        <div className="left lg:w-1/2">
            <img src={aboutpic} alt="" className='rounded-xl' />
        </div>

        <div className="right flex flex-col justify-center items-center lg:w-1/2">
        <h2 className="russo text-3xl lg:text-6xl text-center lg:text-right text-white [#C9B0E7] mb-4 lg:mb-5 uppercase">
      About
      </h2>

      <p className="russo text-sm lg:text-xl text-white [#C9B0E7] w-11/12 lg:w-10/12 text-left mb-20 lg:mb-auto">
     
      Investing in cryptocurrencies can be a daunting task, but what if we told you there's a unique opportunity that promises both profitability and endless entertainment? Introducing SnoozeCoin, the newest addition to the digital currency market. This cryptocurrency is the brainchild of a legendary feline, SnoozeCat, who, with his napping prowess and soft fur, ruled the kingdom of CryptoLand.
      <br/><br/>
SnoozeCat's quest to uncover the elusive SnoozeCoin is nothing short of inspiring. Along with his companions, MemeMouser and CryptoOwl, SnoozeCat navigated through meme-infested forums and outwitted crypto bears, all while keeping their wit and humor intact. Their journey culminated in the triumphant discovery of the SnoozeCoin, unleashing a tidal wave of memes and merriment across the digital landscape.
<br/><br/>
The tale of SnoozeCat and SnoozeCoin($Zzzzcat) has quickly spread far and wide, inspiring joy and laughter in the hearts of crypto enthusiasts and meme connoisseurs. And with SnoozeCoin's promising potential for profits and a community of enthusiastic investors, it's the perfect way to diversify your investment portfolio.
<br/><br/>
As a cat lover and meme enthusiast, you don't want to miss out on the chance to be a part of the purrfect investment community. Join the $ZZZZCAT meme party and let the SnoozeCat take you on a profitable dreamland adventure. With SnoozeCoin, you'll not only invest in a promising cryptocurrency, but you'll also have fun doing it. Don't let this opportunity pass you by.
    
           </p>
        </div>
    </div>
  )
}

export default About